@import "../../../../app/styles/constants";

.vehicle-mappings-row {
  padding-top: @tiny-space;

  .ant-form-item {
    margin-bottom: @small-space;
  }

  &.edited-row {
    background-color: @background-shadow-blue;
  }
}

@primary-color: #80b55c;@info-color: #1890ff;