@import "../../../../../../app/styles/constants";

.travel-calc-results-container {
  min-height: calc(100vh - 96px - 2 * @medium-space);
  width: 100%;

  .result-title {
    padding-left: @small-space;
  }

  .result-data {
    padding: @tiny-space @small-space @tiny-space @small-space;
    margin-bottom: @tiny-space;
    border: 1px solid transparent;
    transition: all .5s;

    &:hover {
      background-color: @background-shadow;
      border: 1px solid @blue-opacity;
    }

    &.result-selected {
      background-color: @background-shadow-green;

      &:hover {
        background-color: @background-shadow-green-strong;
      }
    }

    h4 {
      color: @blue;
      border-bottom: 1px solid @grey-border;
    }
  }
}

.travel-calc-results-navigation {
  margin: @small-space 0 -@medium-space -@medium-space;
  width: calc(100% + @large-space) !important;

  .ant-affix {
    box-shadow: 0 -5px 5px -5px @background-shadow-strong;
    background-color: @white;
  }

  div:not(.ant-affix) {
    box-shadow: 0 -5px 5px -5px @background-shadow-strong;
  }

  .actions-center {
    box-shadow: none !important;
    text-align: center;
  }

  .actions-right {
    box-shadow: none !important;
    text-align: right;
    margin-top: -48px;
  }

  button {
    margin: @tiny-space @small-space;
  }
}

@primary-color: #80b55c;@info-color: #1890ff;