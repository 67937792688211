@import "../../../app/styles/constants";

.contacts-table-view {
  td {
    padding: 0 @tiny-space @tiny-space 0;
  }

  td:nth-child(1), td:nth-child(2) {
    color: @black;
  }
}

@primary-color: #80b55c;@info-color: #1890ff;