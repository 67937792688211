@import "../../../app/styles/constants";

.action-icon {
  cursor: pointer;

  &.delay-effect {
    transition: all .5s;
  }

  i {
    margin-right: 0;
  }

  &.red {
    color: @red-opacity;

    &:hover {
      color: @red;
    }
  }

  &.green {
    color: @primary-color-opacity;

    &:hover {
      color: @primary-color;
    }
  }

  &.blue {
    color: @blue-opacity;

    &:hover {
      color: @blue;
    }
  }

  &.orange {
    color: @orange-opacity;

    &:hover {
      color: @orange;
    }
  }

  &.small {
    font-size: 0.9em;
  }

  &.large {
    font-size: 1.1em;
  }

  &.huge {
    font-size: 1.2em;
  }

  &.disabled {
    color: @grey-opacity;
    cursor: not-allowed;
  }
}

.delete-icon {
  margin-top: 32px;
  font-size: 24px;
  color: @grey-icon !important;
  transition: all .5s;
  cursor: pointer;

  &:hover {
    color: @red !important;
  }
}

.edit-icon {
  margin-left: 8px;
  font-size: 16px;
  color: @grey-icon !important;
  transition: all .5s;

  &:hover {
    color: @blue !important;
  }
}

.popconfirm-delete-icon {
  color: @red !important;
}

.file-type-icon {
  &.small {
    font-size: 0.9em;
  }

  &.large {
    font-size: 1.1em;
  }

  &.huge {
    font-size: 1.2em;
  }
}

.timeline-dot-icon {
  font-size: 16px;
}
