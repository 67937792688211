@import "../styles/constants";

.root-layout {
  min-height: 100vh !important;

  &.dev {
    & > section.ant-layout > section.ant-layout, .ant-layout-footer, .bottom-fixed-navigation {
      background: @profile-dev-color-background;
    }
  }

  &.test {
    & > section.ant-layout > section.ant-layout, .ant-layout-footer, .bottom-fixed-navigation {
      background: @profile-test-color-background;
    }
  }

  &.demo {
    & > section.ant-layout > section.ant-layout, .ant-layout-footer, .bottom-fixed-navigation {
      background: @profile-demo-color-background;
    }
  }
}

.ant-layout {

  .ant-layout-header {
    background: @white;
    padding: 0;
    text-align: left;
    height: 54px;
    line-height: 54px;

    .logo {
      margin-left: @small-space;
      float: left;

      img {
        max-height: 36px;
      }

      .active-profile {
        position: absolute;
        line-height: initial;
        top: 16px;
        padding: 2px;
        font-weight: bold;
        color: @red;
        border-radius: 3px;

        &.dev {
          left: 42px;
          background-color: @profile-dev-color-background;
          border: solid @profile-dev-color 2px;
        }

        &.test {
          left: 38px;
          background-color: @profile-test-color-background;
          border: solid @profile-test-color 2px;
        }

        &.demo {
          left: 34px;
          background-color: @profile-demo-color-background;
          border: solid @profile-demo-color 2px;
        }
      }
    }

    .ant-menu {
      text-align: right;
      margin-right: @small-space;
      line-height: 52px;
      padding-bottom: 2px;

      .ant-menu-item {
        top: 0;
        margin: 0;
        padding-left: 20px;
        padding-right: 20px;

        &:hover {
          border-bottom-color: @primary-color-opacity;
        }

        a:hover {
          text-decoration: none !important;
        }
      }

      .ant-menu-item-selected {
        background-color: @primary-color-opacity;
        border-bottom-color: @primary-color-dark;

        a {
          color: @white;
        }
      }

      .ant-menu-item-logout:hover {
        color: @red;
        border-bottom-color: @red;
      }

      .anticon {
        margin: 0;
        font-size: 1.2em;
      }
    }

    .header-user-section {
      float: left;
      max-width: 350px;
      margin-left: @tiny-space;

      .header-info {
        float: right;
        margin-top: @tiny-space;
        line-height: initial;

        .header-sub-info {
          font-size: 0.9em;
          font-style: italic;
        }
      }
    }
  }

  .ant-layout-sider {
    background-color: @white;

    .ant-menu {
      height: 100%;
      border-top: 1px solid @grey-border;

      .ant-menu-item {
        a:hover {
          text-decoration: none !important;
        }
      }
    }

    .ant-layout-sider-trigger {
      background-color: @white;
      color: @black-opacity;
      border-top: 2px solid @grey-border;
      border-right: 1px solid @grey-border;

      &:hover {
        background-color: @primary-color-opacity;
      }
    }
  }

  .ant-layout-content {
    margin: @small-space @small-space 0 @small-space;
    padding: @medium-space;
    background: @white;
  }

  .ant-layout-footer {
    text-align: center;
    padding: 5px @small-space;
  }
}
