@import "../../../../../app/styles/constants";

.person-tree-node {
  cursor: default;

  &.deactivated {
    font-style: italic;
    color: @grey-opacity;
  }

  .show-detail-icon {
    visibility: hidden;
  }

  &:hover {
    .show-detail-icon {
      visibility: initial;
    }
  }
}

@primary-color: #80b55c;@info-color: #1890ff;