@import "../../../app/styles/constants";

.copy-to-clipboard-view {
  .anticon {
    visibility: hidden;
    color: @blue;
    cursor: pointer;
    font-size: 14px;
  }

  &:hover .anticon {
    visibility: visible;
  }
}

@primary-color: #80b55c;@info-color: #1890ff;