.clients-list-drawer-view {
  td.client-name:hover {
    text-decoration: underline;
    cursor: pointer;

    .detail-icon {
      visibility: initial;
    }
  }

  .detail-icon {
    visibility: hidden;
  }
}

@primary-color: #80b55c;@info-color: #1890ff;