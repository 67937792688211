@import "../../../app/styles/constants.less";

.history-view-table {
  margin-bottom: @small-space;
  transition: all .5s;
  border: solid transparent 1px;
  border-radius: 5px;

  &:hover {
    background-color: @background-shadow-light;
    border: solid @blue-opacity 1px;
  }

  th {
    display: none;
  }

  .ant-table-title {
    padding-top: @tiny-space;
    padding-bottom: 0;

    h4 {
      margin-bottom: 0;
    }
  }

  .ant-table tbody td {
    padding: 2px 8px !important;
  }

  .history-item-label-column {
    width: 18%;
    font-weight: 500;
  }

  .history-item-value-column {
    width: 41%;
  }
}

@primary-color: #80b55c;@info-color: #1890ff;