@import "./constants";
@import "../components/layout";
@import "../../common/components/icons/icons";

@import '~antd/dist/antd.less';

body {
  margin: 0;
  padding: 0;
}

#root {
  height: 100%;
}

.ant-spin-nested-loading {
  height: 100%;
}

.ant-spin-container {
  height: 100%;
}

.standard-container {
  width: 100%;
  max-width: 900px;
}

a:hover {
  text-decoration: underline !important;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.cursor-help {
  cursor: help;
}

.center-align {
  text-align: center;
}

.left-align {
  text-align: left;
}

.right-align {
  text-align: right;
}

.table-column-right-align {
  text-align: right !important;
}

.right-float {
  float: right;
}

.left-float {
  float: left;
}

.clear-both {
  clear: both;
}

.display-none {
  display: none !important;
}

.margin-bottom-tiny {
  margin-bottom: @tiny-space;
}

.margin-bottom-small {
  margin-bottom: @small-space;
}

.margin-bottom-medium {
  margin-bottom: @medium-space;
}

.margin-bottom-large {
  margin-bottom: @large-space;
}

.margin-left-tiny {
  margin-left: @tiny-space;
}

.margin-left-small {
  margin-left: @small-space;
}

.margin-left-medium {
  margin-left: @medium-space;
}

.margin-left-large {
  margin-left: @large-space;
}

.margin-right-tiny {
  margin-right: @tiny-space;
}

.margin-right-small {
  margin-right: @small-space;
}

.margin-right-medium {
  margin-right: @medium-space;
}

.margin-right-large {
  margin-right: @large-space;
}

.margin-top-tiny {
  margin-top: @tiny-space;
}

.margin-top-small {
  margin-top: @small-space;
}

.margin-top-medium {
  margin-top: @medium-space;
}

.margin-top-large {
  margin-top: @large-space;
}

.bold-text {
  font-weight: bold;
}

.highlighted-text-big {
  font-weight: bold;
  font-size: 1.2em;
  color: @red;
}

.highlighted-text {
  font-weight: bold;
  color: @red;
}

.no-break {
  white-space: nowrap;
}

.multiple-rows-label .ant-form-item-label {
  label {
    white-space: normal;
    display: inline-block;
    text-align: center;
    line-height: 16px;
    margin-top: 2px;
  }
}

form.ant-legacy-form {

  .ant-picker {
    width: 100%;
  }

  div.ant-input-number {
    width: 100%;
  }

  .form-item-without-label {
    margin-top: 34px;
  }

  .two-line-form-item-without-label {
    div > div > span > label > span:nth-child(1) {
      margin-top: 38px;
      float: left;
    }

    div > div > span > label > span:nth-child(2) {
      display: block;
      margin: 26px 0 0 16px;
      line-height: 1.3em;
    }
  }

  .three-line-form-item-without-label {
    div > div > span > label > span:nth-child(1) {
      margin-top: 38px;
      float: left;
    }

    div > div > span > label > span:nth-child(2) {
      display: block;
      margin: 16px 0 0 16px;
      line-height: 1.3em;
    }
  }

  .two-line-form-item {
    div > div > span > label > span:nth-child(1) {
      margin-top: 12px;
      float: left;
    }

    div > div > span > label > span:nth-child(2) {
      display: block;
      margin: 0 0 0 16px;
      line-height: 1.3em;
    }
  }
}

form.ant-form {

  .ant-picker {
    width: 100%;
  }

  div.ant-input-number {
    width: 100%;
  }

  .form-item-without-label {
    margin-top: 30px;
  }

  .control-item-without-label {
    margin-top: 34px;
  }

  .form-item-required::before {
    display: inline-block;
    margin-right: 4px;
    color: #ff4d4f;
    font-size: 14px;
    font-weight: normal;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: '*';
  }

  .two-line-form-item-without-label {
    div > div > span > label > span:nth-child(1) {
      margin-top: 38px;
      float: left;
    }

    div > div > span > label > span:nth-child(2) {
      display: block;
      margin: 26px 0 0 16px;
      line-height: 1.3em;
    }
  }

  .three-line-form-item-without-label {
    div > div > span > label > span:nth-child(1) {
      margin-top: 38px;
      float: left;
    }

    div > div > span > label > span:nth-child(2) {
      display: block;
      margin: 16px 0 0 16px;
      line-height: 1.3em;
    }
  }

  .two-line-form-item {
    div > div > span > label > span:nth-child(1) {
      margin-top: 12px;
      float: left;
    }

    div > div > span > label > span:nth-child(2) {
      display: block;
      margin: 0 0 0 16px;
      line-height: 1.3em;
    }
  }
}

.ant-card-head-title {
  font-weight: bold;
}

.ant-steps {
  .ant-steps-item {
    .ant-steps-item-content {
      .ant-steps-item-description {
        max-width: initial;
      }
    }
  }
}

div.ant-tag {
  cursor: default;
}

.ant-divider {
  &.ant-divider-horizontal {
    color: @grey-border;
  }

  .ant-divider-inner-text {
    color: rgba(0, 0, 0, .85);
  }
}

.statistic-small {
  .ant-statistic-content {
    font-size: 20px;
  }
}

.divider-subheader {
  font-size: 1em !important;
}

.divider-with-small-margins {
  margin: 4px 0 !important;
}

.icon-black-opacity {
  color: @grey-opacity;
}

.table-row-stripped {
  .ant-table tbody tr.ant-table-row-level-0:nth-child(even) {
    background-color: @background-shadow;
  }

  .ant-table tbody tr.deactivated {
    background-color: @background-deactivated !important;
  }

  .ant-table th {
    background-color: @background-shadow-middle !important;
    font-weight: bold;
  }

  .ant-table .ant-table-tbody > tr:not(.ant-table-expanded-row):hover > td {
    background-color: @primary-color-hover;

    &.display-on-hover * {
      visibility: initial;
    }
  }

  .ant-table tbody td.display-on-hover * {
    visibility: hidden;
  }

  &.no-header-background {
    .ant-table th {
      background-color: unset !important;
    }
  }
}

.data-table-view {
  th {
    padding: 0 @tiny-space @tiny-space 0;
  }

  td {
    padding: 0 @tiny-space @tiny-space 0;
  }

  td:nth-child(even) {
    padding-right: @medium-space;
  }

  td:nth-child(odd) {
    color: @black;
  }

  &.only-first-bold-col {
    td:not(:first-child) {
      color: inherit;
    }
  }
}

.result-additional-info {
  max-width: 600px;
}

.prices-table-view {
  color: @grey;
  width: 100%;

  td, th {
    padding: 0 @tiny-space @tiny-space 0;
  }

  th:not(:first-child) {
    text-align: right;
  }

  td:not(:first-child) {
    text-align: right;
    font-weight: bold;
    padding-left: @tiny-space;
    color: @black;
  }

  &.bottom-border {
    tr {
      border-bottom: 1px solid @grey-border;
    }

    tr:last-child {
      border-bottom: none;
    }

    td {
      padding-top: 2px;
      padding-bottom: 2px;
    }
  }
}

.sub-header-info {
  font-size: 13px;
  color: @grey;
  font-style: italic;

  span {
    font-weight: bold;
  }

  &.dashed {
    &:before {
      content: '- ';
    }

    &:after {
      content: ' -';
    }
  }

  &.normal-font-size {
    font-size: 1em;
  }
}

.card-filter {
  margin: 0 auto;
  margin-bottom: @medium-space !important;

  .ant-card-body {
    padding: 18px 24px 0 24px !important;

    button {
      margin-top: 28px;
    }
  }

  .extended-filter-action {
    margin-bottom: @medium-space !important;
  }
}

.card-box {
  margin-bottom: @medium-space !important;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06) !important; // https://tailwindcss.com/docs/box-shadow/

  .ant-card-head {
    color: @blue !important;
  }
}

.page-header-box {
  border: 1px solid @grey-border;
}

.blue-button {
  color: @white !important;
  background: @blue !important;
  border-color: @blue !important;

  &:hover {
    background: @blue-opacity !important;
    border-color: @blue-opacity !important;
  }

  &:disabled {
    // ant design style
    color: @grey-opacity !important;
    background-color: #f5f5f5 !important;
    border-color: #d9d9d9 !important;
    text-shadow: none;
    box-shadow: none;
  }
}

.bottom-fixed-navigation {
  position: fixed;
  bottom: 0;
  left: inherit;
  height: 64px;
  width: calc(100% - (@side-menu-width + 2 * @small-space));
  z-index: 99;
  margin-left: -@medium-space;
  background-color: @background;
  box-shadow: 0 -5px 5px -5px @background-shadow-strong;

  .navigation-center {
    padding: @tiny-space 0;
    background-color: @white;
    text-align: center;
  }

  .navigation-right {
    padding-right: @tiny-space;
    background-color: @white;
    text-align: right;
    margin-top: -40px;
  }

  .navigation-left {
    padding-left: @tiny-space;
    background-color: @white;
    text-align: left;
    margin-top: -32px;
  }
}

.result-page {
  .ant-result-title {
    color: @primary-color;
  }
}

.modal-code-block {
  overflow: auto;
  height: 65vh;
  background-color: @background-shadow;
}

@primary-color: #80b55c;@info-color: #1890ff;