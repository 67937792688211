.client-search-input {
  .ant-form-item-label {
    height: 28px;
  }

  label {
    width: 100%;

    .ant-row {
      width: 100%;
    }
  }
}

.client-search-view {
  margin-top: 33px;
}

@primary-color: #80b55c;@info-color: #1890ff;