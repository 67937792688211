.input-number-with-addon {
  top: 0 !important;

  .main-component {
    width: 83% !important;
  }

  .addon-component {
    text-align: center;
    width: 17% !important;
    padding: 4px 0;
    cursor: default;

    &:hover {
      border-color: #d9d9d9;
    }
  }
}

@primary-color: #80b55c;@info-color: #1890ff;